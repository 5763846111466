import {
  ISegmentCustomerProfileCriteria,
  ISegmentGroupCriteria,
  InequalityOperator,
  SegmentCriteria,
  StringOperator,
  TriggerTimeFrame,
  TriggerTimeFrames
} from 'types'

export type OrderCriteriaOptionValue =
  | 'placed_order'
  | 'not_placed_order'
  | 'never_placed_order'
  | 'spent'
  | 'not_spent'
  | 'received_campaign'
  | 'not_received_campaign'

export type SubscriptionCriteriaOptionValue = 'opted_in' | 'not_opted_in'

export type LoyaltyRedeemedCriteriaOptionValue =
  | 'redeemed_loyalty'
  | 'not_redeemed_loyalty'

export type LoyaltyEarnedCriteriaOptionValue =
  | 'earned_loyalty'
  | 'not_earned_loyalty'

export type CustomerCriteriaOptionValue =
  ISegmentCustomerProfileCriteria['field']

export interface CriteriaOption {
  value: SegmentCriteriaOptionValue
  text: string
  group?: string
}

export const segmentTemplateCustomerProfileOptions: CriteriaOption[] = [
  {
    value: 'birth_date',
    text: 'Birthday'
  },
  {
    value: 'birth_month',
    text: 'Birth month'
  },
  /* {
    value: 'join_date',
    text: 'Join date anniversary'
  }, */
  {
    value: 'email_address',
    text: 'Email address'
  } /* ,
  {
    value: 'preferred_store',
    text: 'Preferred store'
  } */
]

export const birthMonthOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
].map((month, index) => ({
  value: `${index}`,
  text: month
}))

export const segmentTemplateOrderOptions: CriteriaOption[] = [
  // TODO: Uncomment as segment criteria are implemented
  {
    value: 'placed_order',
    text: 'Has placed'
  },
  {
    value: 'not_placed_order',
    text: 'Has not placed'
  } /*
  {
    value: 'never_placed_order',
    text: 'Has never placed an order'
  }, */,
  {
    value: 'spent',
    text: 'Has spent'
  },
  {
    value: 'not_spent',
    text: 'Has not spent'
  }
]

export const segmentTemplateCampaignHistoryOptions: CriteriaOption[] = [
  {
    value: 'received_campaign',
    text: 'Has received'
  },
  {
    value: 'not_received_campaign',
    text: 'Has not received'
  }
]

export const segmentTemplateSubscriptionOptions: CriteriaOption[] = [
  {
    value: 'opted_in',
    text: 'Has opted'
  },
  {
    value: 'not_opted_in',
    text: 'Has not opted'
  }
]

export const segmentTemplateLoyaltyOptions: CriteriaOption[] = [
  {
    value: 'earned_loyalty',
    text: 'Has earned'
  },
  {
    value: 'not_earned_loyalty',
    text: 'Has not earned'
  },
  {
    value: 'redeemed_loyalty',
    text: 'Has redeemed'
  },
  {
    value: 'not_redeemed_loyalty',
    text: 'Has not redeemed'
  }
]

export const segmentTemplateOptions: CriteriaOption[] = [
  {
    value: 'birth_date',
    text: 'Birthday',
    group: 'Customer Profile'
  },
  {
    value: 'birth_month',
    text: 'Birth month',
    group: 'Customer Profile'
  },
  {
    value: 'email_address',
    text: 'Email address',
    group: 'Customer Profile'
  },
  // TODO: Uncomment as segment criteria are implemented
  /* {
    value: 'preferred_store',
    text: 'Preferred store',
    group: 'Customer Profile'
  }, */
  {
    value: 'placed_order',
    text: 'Has placed an order',
    group: 'Order History'
  },
  /* {
    value: 'never_placed_order',
    text: 'Has never placed an order',
    group: 'Order History'
  }, */
  {
    value: 'received_campaign',
    text: 'Has received a campaign',
    group: 'Campaign History'
  },
  {
    value: 'not_received_campaign',
    text: 'Has not received a campaign',
    group: 'Campaign History'
  },
  {
    value: 'redeemed_loyalty',
    text: 'Has redeemed a loyalty reward',
    group: 'Loyalty'
  },
  {
    value: 'not_redeemed_loyalty',
    text: 'Has not redeemed a loyalty reward',
    group: 'Loyalty'
  },
  {
    value: 'earned_loyalty',
    text: 'Has earned a loyalty reward',
    group: 'Loyalty'
  },
  {
    value: 'not_earned_loyalty',
    text: 'Has not earned a loyalty reward',
    group: 'Loyalty'
  } /* ,
  {
    value: 'spent',
    text: 'Has spent a specific amount',
    group: 'Order History'
  },
  {
    value: 'not_spent',
    text: 'Has not spent a specific amount',
    group: 'Order History'
  } */
]

export type SegmentCriteriaOptionValue =
  | OrderCriteriaOptionValue
  | SubscriptionCriteriaOptionValue
  | SegmentGroupCriteriaOptionValue
  | CustomerCriteriaOptionValue
  | LoyaltyRedeemedCriteriaOptionValue
  | LoyaltyEarnedCriteriaOptionValue

export type SegmentGroupCriteriaOptionValue =
  | 'group_and'
  | 'group_or'
  | 'group_not_or'
  | 'group_not_and'

export interface SegmentCriteriaCategory {
  value: string
  text: string
}

export const segmentGroupConditionOptions = [
  { value: 'AND', text: 'and' },
  { value: 'OR', text: 'or' }
]

export const comunicationChannelOptions = [
  { value: 'EMAIL', text: 'Email' },
  { value: 'SMS', text: 'SMS' },
  { value: 'PUSH', text: 'Push' }
]

export const segmentGroupCriteriaOptions: CriteriaOption[] = [
  {
    text: 'Select customers who meet all conditions',
    value: 'group_and'
  },
  {
    text: 'Select customers who meet any condition',
    value: 'group_or'
  },
  {
    text: 'Exclude customers who meet any condition',
    value: 'group_not_or'
  },
  {
    text: 'Exclude customers who meet all conditions',
    value: 'group_not_and'
  }
]

export const segmentCategoryOptions: SegmentCriteriaCategory[] = [
  {
    value: 'customer_profile',
    text: 'Custoper Profile'
  },
  {
    value: 'order_history',
    text: 'Order History'
  },
  {
    value: 'subscription_status',
    text: 'Subscription Status'
  },
  {
    value: 'loyalty',
    text: 'Loyalty'
  }
]

export const getSegmentTypeCriteria = (
  segmentType: string
): SegmentCriteria => {
  switch (segmentType) {
    case 'placed_order':
      return {
        type: 'ORDERING',
        operator: 'GREATER_THAN_OR_EQUAL_TO',
        order_count: 1,
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days,
        stores: null,
        order_types: null,
        service_types: null,
        channels: null
      }
    case 'not_placed_order':
      return {
        type: 'ORDERING',
        operator: 'GREATER_THAN',
        order_count: 0,
        negate: true,
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days,
        stores: null,
        order_types: null,
        service_types: null,
        channels: null
      }
    case 'never_placed_order':
      return {
        type: 'ORDERING',
        operator: 'EQUALS',
        order_count: 0,
        duration_length: null,
        duration_units: TriggerTimeFrame.Days,
        stores: null,
        order_types: null,
        service_types: null,
        channels: null
      }
    case 'spent':
      return {
        type: 'ORDERING',
        operator: 'GREATER_THAN',
        order_count: null,
        spend_amount: 0,
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days,
        stores: null,
        order_types: null,
        service_types: null,
        channels: null
      }
    case 'not_spent':
      return {
        type: 'ORDERING',
        operator: 'GREATER_THAN',
        order_count: null,
        negate: true,
        spend_amount: 0,
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days,
        stores: null,
        order_types: null,
        service_types: null,
        channels: null
      }
    case 'opted_in':
      return {
        type: 'SUBSCRIPTION_STATUS',
        channels: ['EMAIL']
      }
    case 'not_opted_in':
      return {
        type: 'SUBSCRIPTION_STATUS',
        channels: ['EMAIL'],
        negate: true
      }
    case 'birth_date':
      return {
        type: 'PROFILE',
        field: 'birth_date',
        operator: 'LESS_THAN',
        value: 7
      }
    case 'birth_month':
      return {
        type: 'PROFILE',
        field: 'birth_month',
        operator: 'EQUALS',
        value: 1
      }
    case 'join_date':
      return {
        type: 'PROFILE',
        field: 'join_date',
        operator: 'EQUALS',
        value: 0
      }
    case 'email_address':
      return {
        type: 'PROFILE',
        field: 'email_address',
        operator: 'ENDS_WITH',
        value: ''
      }
    case 'preferred_store':
      return {
        type: 'PROFILE',
        field: 'preferred_store',
        operator: 'ANY',
        value: []
      }
    case 'received_campaign':
      return {
        type: 'CAMPAIGN_HISTORY',
        campaigns: null,
        negate: false,
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days
      }
    case 'not_received_campaign':
      return {
        type: 'CAMPAIGN_HISTORY',
        campaigns: null,
        negate: true,
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days
      }
    case 'redeemed_loyalty':
      return {
        type: 'LOYALTY_REDEEMED',
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days,
        loyalty_programs: null,
        discounts: null,
        negate: false
      }
    case 'not_redeemed_loyalty':
      return {
        type: 'LOYALTY_REDEEMED',
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days,
        loyalty_programs: null,
        discounts: null,
        negate: true
      }
    case 'earned_loyalty':
      return {
        type: 'LOYALTY_EARNED',
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days,
        loyalty_programs: null,
        discounts: null,
        negate: false
      }
    case 'not_earned_loyalty':
      return {
        type: 'LOYALTY_EARNED',
        duration_length: 30,
        duration_units: TriggerTimeFrame.Days,
        loyalty_programs: null,
        discounts: null,
        negate: true
      }
    default:
      return { type: '' }
  }
}

export const getSegmentGroupCriteria = (
  segmentType: string
): ISegmentGroupCriteria => {
  switch (segmentType) {
    case 'group_and':
      return {
        type: 'GROUP',
        condition: 'AND',
        negate: false,
        rules: []
      }
    case 'group_or':
      return {
        type: 'GROUP',
        condition: 'OR',
        negate: false,
        rules: []
      }
    case 'group_not_or':
      return {
        type: 'GROUP',
        condition: 'OR',
        negate: true,
        rules: []
      }
    case 'group_not_and':
      return {
        type: 'GROUP',
        condition: 'AND',
        negate: true,
        rules: []
      }
    default:
      return { type: 'GROUP', rules: [] }
  }
}

export const segmentCriteriaNumberOperatorOptions: {
  value: InequalityOperator
  text: string
}[] = [
  { value: 'LESS_THAN', text: 'less than' },
  { value: 'LESS_THAN_OR_EQUAL_TO', text: 'at most' },
  { value: 'EQUALS', text: 'exactly' },
  {
    value: 'GREATER_THAN_OR_EQUAL_TO',
    text: 'at least'
  },
  { value: 'GREATER_THAN', text: 'more than' }
]

export const segmentCriteriaStringOperatorOptions: {
  value: StringOperator
  text: string
}[] = [
  { value: 'CONTAINS', text: 'contains' },
  { value: 'EQUALS', text: 'equals' },
  { value: 'STARTS_WITH', text: 'starts with' },
  { value: 'ENDS_WITH', text: 'ends with' }
]

export const segmentTimeFrameOptions: {
  value: TriggerTimeFrames
  text: string
}[] = [
  { value: 'DAYS', text: 'days' },
  { value: 'MONTHS', text: 'months' }
]

export const segmentOrderCriteriaTypeOptions: {
  value: string
  text: string
}[] = [
  { value: 'channels', text: 'Order channel' },
  { value: 'stores', text: 'Store' },
  { value: 'service_types', text: 'Service type' },
  { value: 'order_types', text: 'Order type' }
]

export const segmentFilterOptions: {
  value: string
  text: string
}[] = [
  { value: 'false', text: 'Active' },
  { value: 'true', text: 'Archived' },
  { value: 'none', text: 'All' }
]
