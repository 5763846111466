import styled from '@emotion/styled'
import TextHeading from './TextHeading'
import { EditPencil } from 'iconoir-react'
import TextBody from './TextBody'

const MarketingHeaderView = styled.div`
  label: MarketingHeader;
  padding: 0.5rem 0 2.5rem;
  border-bottom: ${props => props.theme.layout.desktop.border} solid
    ${props => props.theme.colors.border.primary};

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-top: 4.5rem;
    padding: 1.5rem 0 1rem;
    border-bottom: ${props => props.theme.layout.mobile.border} solid
      ${props => props.theme.colors.border.primary};
  }
`

const MarketingHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    align-items: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: 1rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: 1rem;
  }
`

const MarketingHeaderIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`

const MarketingHeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const MarketingHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  .marketing-title-edit {
    margin-top: 0.5rem;
    display: none;
    opacity: 0;
  }

  &:hover .marketing-title-edit {
    margin-top: 0.5rem;
    display: block;
    opacity: 1;
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const MarketingHeaderTitle = styled(TextHeading)<{ size: 'large' | 'small' }>`
  margin-left: -0.2rem;
  font-size: ${props =>
    props.size === 'large' ? props.theme.sizes.h1 : props.theme.sizes.h3};

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-left: 0;
    font-size: ${props =>
      props.size === 'large' ? props.theme.sizes.h3 : props.theme.sizes.h4};
  }
`

const MarketingHeaderSubtitle = styled(TextBody)`
  max-width: 64rem;
  margin: 1rem 0 0;
  line-height: 1.4;
  text-overflow: ellipsis;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    text-align: center;
    font-size: ${props => props.theme.sizes.small};
  }
`

const MarketingHeaderEditWrapper = styled.span`
  cursor: pointer;
`

const MarketingHeader = ({
  title,
  subtitle,
  icon,
  children,
  size = 'small',
  onEditClick
}: {
  title: React.ReactNode
  subtitle?: React.ReactNode
  icon?: React.ReactNode
  children?: React.ReactNode
  size?: 'large' | 'small'
  onEditClick?: () => void
}) => {
  return (
    <MarketingHeaderView className="marketing-header">
      <MarketingHeaderContainer>
        <MarketingHeaderTitleContainer onClick={onEditClick}>
          {icon && <MarketingHeaderIcon>{icon}</MarketingHeaderIcon>}
          {title && (
            <MarketingHeaderTitleWrapper>
              <MarketingHeaderTitle size={size}>{title}</MarketingHeaderTitle>
              {subtitle && (
                <MarketingHeaderSubtitle as="p" size="medium" color="secondary">
                  {subtitle}
                </MarketingHeaderSubtitle>
              )}
            </MarketingHeaderTitleWrapper>
          )}
          {onEditClick && (
            <MarketingHeaderEditWrapper className="marketing-title-edit">
              <EditPencil fontSize={size === 'large' ? 18 : 12} />
            </MarketingHeaderEditWrapper>
          )}
        </MarketingHeaderTitleContainer>
        {children}
      </MarketingHeaderContainer>
    </MarketingHeaderView>
  )
}

export default MarketingHeader
