import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { SavedContent } from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'

export interface SavedContentState {
  data: SavedContent | null
  loading: RequestStatus
  error: RequestError
}

const initialState: SavedContentState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export type UpdateSavedContentParams = Pick<
  SavedContent,
  | 'campaign_content_id'
  | 'campaign_id'
  | 'channel'
  | 'subject'
  | 'title'
  | 'description'
  | 'is_archived'
  | 'is_published'
  | 'sender_email'
  | 'sender_name'
  | 'editor_config'
  | 'preview_text'
  | 'content_type'
  | 'body'
  | 'reply_to'
  | 'is_locked'
>
export type CreateSavedContentParams = Partial<
  Omit<UpdateSavedContentParams, 'campaign_content_id'>
>
export type PatchSavedContentParams = Partial<UpdateSavedContentParams>

export const createSavedContent = createAsyncThunk<
  SavedContent,
  CreateSavedContentParams,
  { state: RootState; rejectValue: RequestError }
>(
  'saved-content',
  async (
    savedContent,
    { getState, dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaign-content`
      const resp = (await api?.request(endpoint, 'POST', {
        title: savedContent.title,
        description: savedContent.description,
        campaign_id: null,
        channel: savedContent.channel ?? 'EMAIL',
        content_type: savedContent.content_type,
        editor_config: savedContent.editor_config,
        preview_text: savedContent.preview_text ?? '',
        sender_email: savedContent.sender_email ?? '',
        sender_name: savedContent.sender_name ?? '',
        subject: savedContent.subject ?? '',
        reply_to: savedContent.reply_to ?? '',
        body: savedContent.body ?? '',
        is_locked: savedContent.is_locked ?? false
      })) as SavedContent
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateSavedContent = createAsyncThunk<
  SavedContent,
  UpdateSavedContentParams,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign-content/updateSavedContent',
  async ({ campaign_content_id, ...data }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaign-content/${campaign_content_id}`
      const resp = (await api?.request(endpoint, 'PUT', {
        campaign_id: data.campaign_id,
        body: data.body,
        channel: data.channel,
        content_type: data.content_type,
        editor_config: data.editor_config,
        preview_text: data.preview_text,
        sender_email: data.sender_email,
        sender_name: data.sender_name,
        subject: data.subject,
        reply_to: data.reply_to,
        title: data.title,
        description: data.description,
        is_locked: data.is_locked,
        is_archived: data.is_archived
      })) as SavedContent
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const patchSavedContent = createAsyncThunk<
  SavedContent | null,
  PatchSavedContentParams,
  { state: RootState; rejectValue: RequestError }
>(
  'saved-content/patchSavedContent',
  async (patch, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const state = getState()
      const api = state.authUser.api
      const content = state.savedContent.data
      if (!content)
        return rejectWithValue({
          code: '400',
          title: 'No content fetched',
          detail: 'Cannot patch empty content',
          status: 400
        } as RequestError)
      const endpoint = `campaign-content/${content.campaign_content_id}`
      const resp = (await api?.request(endpoint, 'PUT', {
        campaign_id: content.campaign_id,
        channel: content.channel,
        body: patch.body ?? content.body,
        title: patch.title ?? content.title,
        description: patch.description ?? content.description,
        content_type: content.content_type,
        editor_config: patch.editor_config ?? content.editor_config,
        preview_text: patch.preview_text ?? content.preview_text,
        sender_email: patch.sender_email ?? content.sender_email,
        sender_name: patch.sender_name ?? content.sender_name,
        subject: patch.subject ?? content.subject,
        reply_to: patch.reply_to ?? content.reply_to,
        is_archived: patch.is_archived ?? content.is_archived,
        is_locked: patch.is_locked ?? content.is_locked
      })) as SavedContent
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const publishSavedContent = createAsyncThunk<
  SavedContent,
  Pick<SavedContent, 'campaign_content_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'saved-content/publishSavedContent',
  async (
    { campaign_content_id },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaign-content/${campaign_content_id}/publish`
      const resp = (await api?.request(endpoint, 'POST')) as SavedContent
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)
export const fetchSavedContent = createAsyncThunk<
  SavedContent,
  { savedContentId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'saved-content/fetchSavedContent',
  async (
    { savedContentId },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const queryParams = makeQueryParams({ with_related: true })
      const api = getState().authUser.api
      const endpoint = `campaign-content/${savedContentId}${queryParams}`
      const resp = (await api?.request(endpoint)) as SavedContent
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteSavedContent = createAsyncThunk<
  void,
  { savedContentId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/deleteSavedContent',
  async (
    { savedContentId },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaign-content/${savedContentId}`
      await api?.request(endpoint, 'DELETE')
      return fulfillWithValue(undefined)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const savedContentSlice = createSlice({
  name: 'saved-content',
  initialState,
  reducers: {
    resetSavedContent: () => initialState,
    setSavedContent: (state, action: PayloadAction<SavedContent | null>) => {
      state.data = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(createSavedContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createSavedContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createSavedContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateSavedContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateSavedContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateSavedContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(patchSavedContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(patchSavedContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(patchSavedContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchSavedContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchSavedContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchSavedContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteSavedContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteSavedContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = null
      state.error = null
    })
    builder.addCase(deleteSavedContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(publishSavedContent.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(publishSavedContent.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.error = null
    })
    builder.addCase(publishSavedContent.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetSavedContent, setSavedContent } = savedContentSlice.actions

export const selectSavedContent = (state: RootState) => state.savedContent

export default savedContentSlice.reducer
