import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  Campaign,
  ICampaignIdentity,
  CampaignParams,
  CampaignSegments,
  CampaignPerformance
} from 'types'
import { RequestError, RequestStatus, makeQueryParams } from 'utils'
import { showNotification } from './notification'

export interface CampaignState {
  data: Campaign | null
  loading: RequestStatus
  validating: RequestStatus
  error: RequestError
  contentErrors: { [key: string]: string } | null
  audienceErrors: { [key: string]: string } | null
  scheduleErrors: { [key: string]: string } | null
  summary: CampaignPerformance | null
  summaryError: RequestError
}

export const emptyCampaign: Campaign = {
  campaign_id: 0,
  name: '',
  description: '',
  campaign_type: 'SINGLE_SEND',
  created_at: '',
  updated_at: '',
  campaign_status: 'DRAFT',
  campaign_content: [],
  segments: [],
  triggers: [],
  is_archived: false,
  is_template: false
}

const initialState: CampaignState = {
  data: emptyCampaign,
  loading: RequestStatus.Idle,
  validating: RequestStatus.Idle,
  error: null,
  contentErrors: null,
  audienceErrors: null,
  scheduleErrors: null,
  summary: null,
  summaryError: null
}

export interface FetchCampaignParams extends ICampaignIdentity {
  with_related: string
}

export const createCampaign = createAsyncThunk<
  Campaign,
  Partial<CampaignParams>,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/createCampaign',
  async (campaign, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns`
      const resp = (await api?.request(endpoint, 'POST', {
        name: campaign.name,
        description: campaign.description,
        campaign_type: campaign.campaign_type,
        is_template: campaign.is_template || false
      })) as Campaign
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const duplicateCampaign = createAsyncThunk<
  Campaign,
  ICampaignIdentity,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/duplicateCampaign',
  async (campaign, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/${campaign.campaign_id}/copy`
      const resp = (await api?.request(endpoint, 'POST', {})) as Campaign
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const validateCampaign = createAsyncThunk<
  void,
  { campaignId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/validateCampaign',
  async ({ campaignId }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/${campaignId}/review`
      await api?.request(endpoint)
      return fulfillWithValue(undefined)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCampaignSegments = createAsyncThunk<
  CampaignSegments,
  CampaignSegments,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/updateCampaignSegments',
  async (segments, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/${getState().campaign.data?.campaign_id}/segments`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        segments.map(s => ({
          marketing_segment_id: s.marketing_segment_id,
          use_intersection: s.use_intersection,
          is_excluded: s.is_excluded
        }))
      )) as CampaignSegments
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteCampaignSegments = createAsyncThunk<
  CampaignSegments,
  CampaignSegments,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/deleteCampaignSegments',
  async (segments, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/${getState().campaign.data?.campaign_id}/segments`
      const resp = (await api?.request(
        endpoint,
        'DELETE',
        segments.map(s => ({
          marketing_segment_id: s.marketing_segment_id,
          use_intersection: s.use_intersection,
          is_excluded: s.is_excluded
        }))
      )) as CampaignSegments
      return fulfillWithValue(resp)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateCampaign = createAsyncThunk<
  Campaign,
  ICampaignIdentity & CampaignParams,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/updateCampaign',
  async (
    { campaign_id, name, description, campaign_type, is_template, is_archived },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/${campaign_id}`
      const resp = (await api?.request(endpoint, 'PUT', {
        name,
        description,
        campaign_type,
        is_template,
        is_archived
      })) as Campaign
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchCampaign = createAsyncThunk<
  Campaign,
  FetchCampaignParams,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/fetchCampaign',
  async ({ campaign_id, ...params }, { getState, rejectWithValue }) => {
    try {
      const queryParams = params ? makeQueryParams(params) : ''
      const api = getState().authUser.api
      const endpoint = `campaigns/${campaign_id}${queryParams}`
      const resp = (await api?.request(endpoint)) as Campaign
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteCampaign = createAsyncThunk<
  Campaign,
  {
    campaign_id: string | number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/deleteCampaign',
  async ({ campaign_id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/${campaign_id}`
      const resp = (await api?.request(endpoint, 'DELETE')) as Campaign
      dispatch(showNotification('Campaign deleted successfully'))
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const sendCampaign = createAsyncThunk<
  void,
  Pick<Campaign, 'campaign_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/sendCampaign',
  async ({ campaign_id }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/${campaign_id}/send`
      await api?.request(endpoint, 'POST')
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const pauseCampaign = createAsyncThunk<
  void,
  Pick<Campaign, 'campaign_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/pauseCampaign',
  async ({ campaign_id }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/${campaign_id}/pause`
      await api?.request(endpoint, 'POST')
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchCampaignSummary = createAsyncThunk<
  CampaignPerformance,
  Pick<Campaign, 'campaign_id'>,
  { state: RootState; rejectValue: RequestError }
>(
  'campaign/fetchCampaignSummary',
  async ({ campaign_id }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `campaigns/summary/${campaign_id}`

      return fulfillWithValue(
        (await api?.request(endpoint)) as CampaignPerformance
      )
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    resetCampaign: () => initialState,
    setCampaign: (state, action: PayloadAction<Campaign>) => {
      state.data = action.payload
    },
    setCampaignSegments: (state, action: PayloadAction<CampaignSegments>) => {
      if (state.data) state.data.segments = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(createCampaign.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createCampaign.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.data = payload || initialState.data
      state.error =
        state.contentErrors =
        state.audienceErrors =
        state.scheduleErrors =
          null
    })
    builder.addCase(createCampaign.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(duplicateCampaign.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(duplicateCampaign.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.data = payload
      state.error = null
    })
    builder.addCase(duplicateCampaign.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCampaign.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCampaign.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = {
        ...initialState.data,
        ...state.data,
        ...payload
      }
      state.error = null

      state.contentErrors = state.audienceErrors = state.scheduleErrors = null
    })
    builder.addCase(updateCampaign.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(sendCampaign.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(sendCampaign.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(sendCampaign.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(pauseCampaign.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(pauseCampaign.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(pauseCampaign.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateCampaignSegments.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateCampaignSegments.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      if (state.data)
        state.data = {
          ...state.data,
          segments: payload
        }
      state.error = null
      state.error = state.audienceErrors = null
    })
    builder.addCase(updateCampaignSegments.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteCampaignSegments.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteCampaignSegments.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      if (state.data)
        state.data = {
          ...state.data,
          segments: payload
        }
      state.error = null
    })
    builder.addCase(deleteCampaignSegments.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(validateCampaign.pending, state => {
      state.validating = RequestStatus.Pending
    })
    builder.addCase(validateCampaign.fulfilled, (state, { payload }) => {
      state.validating = RequestStatus.Succeeded
      state.contentErrors = state.audienceErrors = state.scheduleErrors = null
    })
    builder.addCase(validateCampaign.rejected, (state, { payload }) => {
      state.validating = RequestStatus.Failed
      const errors = payload?.params?.errors as { [key: string]: string }
      state.contentErrors = Object.fromEntries(
        Object.entries(errors).filter(([key]) =>
          key.includes('campaign_content')
        )
      )
      state.audienceErrors = Object.fromEntries(
        Object.entries(errors).filter(([key]) => key.includes('segments'))
      )
      state.scheduleErrors = Object.fromEntries(
        Object.entries(errors).filter(([key]) => key.includes('triggers'))
      )
    })
    builder.addCase(fetchCampaign.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCampaign.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Succeeded
      state.data = payload
      state.error = null
    })
    builder.addCase(fetchCampaign.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Failed
      state.error = payload
    })
    builder.addCase(deleteCampaign.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteCampaign.fulfilled, () => initialState)
    builder.addCase(deleteCampaign.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchCampaignSummary.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchCampaignSummary.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.summary = payload
      state.summaryError = null
    })
    builder.addCase(fetchCampaignSummary.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.summaryError = payload
    })
  }
})

export const { resetCampaign, setCampaign, setCampaignSegments } =
  campaignSlice.actions

export const selectCampaign = (state: RootState) => state.campaign

export const selectCampaignSummary = (state: RootState) =>
  state.campaign.summary

export default campaignSlice.reducer
